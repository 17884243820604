import React, { Children, useState } from 'react'
import '../../style-item/details.css'
import IconCall from '../../icons/IconCall'

function TagsCall(props){
    const Tag=props.Tag
   const Val=props.val
    if(Tag==='h1'){
        return <h1 style={{ padding: '7px'}}>{Val}</h1>
    }
    if(Tag==='h2'){
        return <h2 style={{ padding: '7px'}}>{Val}</h2>
    }
    if(Tag==='h3'){
        return <h3 style={{ padding: '7px'}}>{Val}</h3>
    }
    if(Tag==='h4'){
        return <h4 style={{ padding: '7px'}}>{Val}</h4>
    }
    if(Tag==='h5'){
        return <h5 style={{ padding: '7px'}}>{Val}</h5>
    }
    if(Tag==='h6'){
        return <h6 style={{ padding: '7px'}}>{Val}</h6>
    }
    if(Tag==='p'){
        return <p style={{ padding: '7px'}}>{Val}</p>
    }
    if(Tag==='div'){
        return <div style={{ padding: '7px'}}>{Val}</div>
    }
}
function DetailsVlu(props){
    const dataOrder=props.dataOrder
    
    return (<>
        { Object.keys(dataOrder).map((item, i) => (<>{<TagsCall css={dataOrder[item].css} Tag={dataOrder[item].tag} val={item}/>}</>))}</>
    )
}
function Details(props) {
    const Data=props.Data
    const reguard=props.reguard

    if(reguard==='offer'){
        const DataMap=Data.Claim_Details.Tags
        return <><div className='mainDiv' onClick={()=>props.ShowOfferDetailsSet(false)}></div> <div className='holder'><div className='top0 height100 width100 zindex120000 posiab maindetailsitem'>
            {
    Object.keys(DataMap).map((item, i) => (
        <>
      
   
    <div className='sjkOEs'>
    <span class="closebtn" onClick={()=>props.ShowOfferDetailsSet(false)}><IconCall height={'24px'} width={'24px'} color={'black'} icon={'cross'}/></span>
        <h3>{item}</h3></div>
    <div><DetailsVlu dataOrder={DataMap[item]}/> </div></>
    ))}</div>
  </div></>}
  }

export default Details
