import React from 'react'
import Agent from '../deliver/sent'
import '../style-item/profile.css'
import CryptoJS from 'crypto-js';
import IconCall from '../icons/IconCall';
import '../style-item/liner.css'



function UserProfile(props) {
    const refrash=()=>{
        window.location.reload()
    }

   
  const secretPass1 = "sdjkljlsdlkJKjksdlkseOEjd";
  const secretPass2 = "kldsklfjddfdsdlkdfkkldfjkf";
  const secretPass4 = "fjdkdfkljgldfguitytlkseOEjd";
  const secretPass3 = "kfg6tythvcbdffgdfhgfhffjkf";
  const secretPass5 = "sdjkljlsdldfdrtdfdgfgghfEjd";
  const peofileItem=['Orders & History','Book-marked','Save Address','Afflilate','Become A Seller','Offers & Rewards','Give Reviews','Contact Us','Setting','Logout']
  const Icons=['hist','fav','offerRew','addressSave','Affl','Sell','review','helpGu','set','logout']
  function ClickResponse(r){
    function logout(){ sessionStorage.removeItem('uDeE'); sessionStorage.removeItem('uDnE'); sessionStorage.removeItem('uDpE'); sessionStorage.removeItem('uDtE');
        refrash()
    }
    peofileItem[r]==='Logout'?logout():console.log(peofileItem[r] +'function not set')
    
}
  return (<> <div className='diDHph' onClick={()=>props.SignedCheck(false)} style={{zIndex:'10000',background:'#00000026'}}></div>
      
    <div className='y6Z-HFp  My-profile'>
         <div className='k-1652'>
            <div className='user(Maped){} mainsec'>
                <div className='closeMark' onClick={()=>props.SignedCheck(false)}><IconCall icon={'cross'} color={'white'} width={'30px'} height={'30px'}style={{zIndex:'1'}} ></IconCall></div>
                <div className='kljsdf-fs-s;l;sfdsdf(Null)Maped jsdaww'>
                    <div className='dlkpeP uicfs'>
                        <div className='sdExer'>
                            <div className='sdExer'><img style={{borderRadius:'50%',height:'40px'}} src={CryptoJS.AES.decrypt(sessionStorage.getItem('uDpE'), secretPass3).toString(CryptoJS.enc.Utf8)}/></div>
                        <div className='nameDetails'>
                            <div className='uOName'><Agent.Consumer>{prfUn=>(<>{CryptoJS.AES.decrypt(sessionStorage.getItem('uDnE'), secretPass2).toString(CryptoJS.enc.Utf8)}</>)}</Agent.Consumer></div>
                          </div>
                        </div>
                    </div>

                </div>
            </div>
            <div> 
        </div>
       { Object.keys(peofileItem).map((item, i) => (<> <div className='rainbowXYz'>
                <div className='eachItems etchItemIcon etchDivMenu'  onClick={()=>{ClickResponse(i)}}><div className='ecthChildDiv' ><IconCall icon={Icons[item]}/></div><span className='sdeSdde'>{peofileItem[item]}</span></div>
            </div>
            <div> <div className='spaceLine'></div></div></>))}
        </div>
      
    </div></>
  )
}

export default UserProfile
