import React from 'react'

function Cart() {
  return (
    <div>
      this is cart
    </div>
  )
}

export default Cart
