import React from 'react'

function Category() {
  return (
    <div>
      this is catagory
    </div>
  )
}

export default Category
