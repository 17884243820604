import React,{useState} from 'react'
import Map from './Location'
import '../style-item/App.css'
import IconCall from '../icons/IconCall'
import SignIn from './Signinuser'
import HeaderDex from './HeaderDex'
import { useMediaQuery } from 'react-responsive';
import Agent from '../deliver/sent'
import CryptoJS from 'crypto-js'
import '../style-item/headers2.css'

function Top(props) {
  
  const secretPass3 = "kfg6tythvcbdffgdfhgfhffjkf";
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
const SetCustomPeramiter=props.SetCustomPeramiter
    const isLocationEnable=props.isLocationEnable
    const StatusLocation=props.StatusLocation
   const GetMyLocation= props.GetMyLocation
   const SetMyLocation=props.SetMyLocation
   const [userProfilePicture,SetUserProfilePic]=useState(sessionStorage.getItem('uDnE')?true:null)
  const requestLocation=()=>{

    SetMyLocation(false);
    StatusLocation(false)
    localStorage.removeItem('fetchStatusOk');
    localStorage.removeItem('fetchStatusOk');

    
   }
    const Cm=()=>{
        return (<Agent.Consumer>{value=>(value? <button onClick={()=>props.SignedCheck(true)} className='jduiTY' style={userProfilePicture?{backgroundColor:'transparent'}:{backgroundColor:'green'}}>
       {userProfilePicture?<img src={CryptoJS.AES.decrypt(sessionStorage.getItem('uDpE'), secretPass3).toString(CryptoJS.enc.Utf8)} style={{borderRadius:'50%'}} height={'39px'} width={'39px'}></img>: <IconCall icon='verified-user' width={24} height={24} />}
    </button>:<button onClick={()=>props.SignedCheck(true)} className='jduiTY'>
        <IconCall icon='user'/>
    </button>)}</Agent.Consumer>)
    }
    
    const Sm=()=>{
      return ( <button onClick={()=>props.ShowSearchBar(true)} className='jduiTY2'>
      <IconCall icon='search' width={39} height={39} color={'blue'}/>
  </button>)
  }
    
   
    
    const Am=()=>{
        return ( <div className='JsdIe'><div className='kdOer1'><button  className='Lode4r' onClick={()=>props.ShowPopResetLocation(true)}>
          <span className='ygKLpP'> 
              <span className='dsfrW2'>
                <IconCall icon={'pointer'} height={34} width={34}/>
                </span>
                <span className='djerIe'> My App </span>
                <p className='ChangeLocation'>Change Location</p>
                </span>
                <p className='oerRw1'>{ sessionStorage.getItem('LocationUpdated')?localStorage.getItem('Lodsjdfoer')?JSON.parse(localStorage.getItem('Lodsjdfoer')).data.plus_code.compound_code:GetMyLocation&& <Map ShowMap={props.ShowMap} SetMyLocation={SetMyLocation} SetCustomPeramiter={SetCustomPeramiter} type={'locality'} isLocationEnable={isLocationEnable} mul={''} StatusLocation={StatusLocation}/>:GetMyLocation&& <Map ShowMap={props.ShowMap} SetMyLocation={SetMyLocation} SetCustomPeramiter={SetCustomPeramiter} type={'locality'} isLocationEnable={isLocationEnable} mul={''} StatusLocation={StatusLocation}/>}</p></button>
         <div style={{display: 'flex'}}><Sm/><Cm/></div></div></div>)
    }

    const Amh=()=>{
      return ( <div className='orderPopup'>
        <div className='orderHeader'>
        <span className='dsfrW2'>
                <IconCall icon={'pointer'} height={34} width={34}/>
                </span>
  <div className='ldIFDj' style={{fontWeight:'500'}}>Order History</div>
  <Agent.Consumer>{prfUn=>(prfUn?<div className='OrderDiv' onClick={()=>props.SignedCheck(true)} style={{background:'none'}}><img style={{height:'39px',width:'39px',borderRadius:'50%'}} src={CryptoJS.AES.decrypt(sessionStorage.getItem('uDpE'), secretPass3).toString(CryptoJS.enc.Utf8)}/></div>:<div className='OrderDiv' onClick={()=>props.SignedCheck(true)} ><IconCall icon='user'/></div>)}</Agent.Consumer></div>
     </div>)
    }
    const AmC=()=>{
      return ( <div className='orderPopup'>
        <div className='orderHeader'>
        <span className='dsfrW2'>
                <IconCall icon={'pointer'} height={34} width={34}/>
                </span>
  <div className='ldIFDj' style={{fontWeight:'500'}}>Cart Item</div>
  </div>
     </div>)
    }
    const AmCa=()=>{
      return ( <div className='orderPopup'>
        <div className='orderHeader'>
        <span className='dsfrW2'>
                <IconCall icon={'pointer'} height={34} width={34}/>
                </span>
  <div className='ldIFDj' style={{fontWeight:'500'}}>Categories</div>
  </div>
     </div>)
    }
    const Bm=()=>{
      return <>{props.Signed && <SignIn Signed={props.Signed} SignedCheck={props.SignedCheck}/>}</>  
     
    
    }
    const Bd=()=>{
        return<>   <ul className='Dex_y9'>
        <HeaderDex item='contactUs'/> 
     <HeaderDex Login={props.SignedCheck} item='login'/> 
     <HeaderDex item='cart'/>
     <HeaderDex ShowSearchBar={props.ShowSearchBar} item='search'/>
    </ul></>  
      }
    const Ad=()=>{
       return( <div className='Dex_y3'><a className='Dex_y4' ><IconCall icon={'pointer'} height={49} width={34}/></a>
    
    <div className='Dex_y5'onClick={()=>props.ShowPopResetLocation(true)}><span className='Dex_y6'><span className='Dex_y7'> My App
    </span>
    <p className='ChangeLocation'>Change Location</p>
    </span>
 
    <span className='Dex_y8'>
    { sessionStorage.getItem('LocationUpdated')?localStorage.getItem('Lodsjdfoer')?JSON.parse(localStorage.getItem('Lodsjdfoer')).data.plus_code.compound_code:GetMyLocation&& <Map ShowMap={props.ShowMap} SetMyLocation={SetMyLocation} SetCustomPeramiter={SetCustomPeramiter} type={'locality'} isLocationEnable={isLocationEnable} mul={''} StatusLocation={StatusLocation}/>:GetMyLocation&& <Map ShowMap={props.ShowMap} SetMyLocation={SetMyLocation} SetCustomPeramiter={SetCustomPeramiter} type={'locality'} isLocationEnable={isLocationEnable} mul={''} StatusLocation={StatusLocation}/>}
   </span> <span><IconCall icon={'customPointer'} color={'#5985E1'} height={25} width={25}/></span>
    </div>
 <Bd/>
    </div>)
    }
  return (
   <>{isMobile&&<div>
       {props.ShowMenu==='home'? <Am/>: props.ShowMenu==='orders'?<Amh/>:props.ShowMenu==='Mcart'?<AmC/>:<AmCa/>}
      <Bm/>
    </div>}
    {isDesktop&& <div>  <Ad/>
    </div> }
    </> 
  )
}

export default Top;
