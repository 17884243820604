import React, { useEffect, useState } from 'react'
import '../../style-item/history.css'
import useWindowDimensions from '../../SDK/widthHeight';
import { isRouteErrorResponse } from 'react-router-dom';


function History(props) {
  const [ItemSelected,SetSelectedItem]=useState(1)
   const { height, width } = useWindowDimensions();
  const [Width,SWidth]=useState(550)
 

  useEffect(()=>{
  SWidth(width>550?550:width>768?170:width<550&&width)},[])
  const Picked=()=>{
    return ( <div className='histElim1 botLEvl' style={{width:width/3,background:ItemSelected===1?'rgb(246 174 0)':ItemSelected===2?'rgb(11 67 0 / 86%)':'#ff3829'}}></div>)
  }
  const PickedPC=()=>{
    return ( <div className='histElim1 pcdUed' style={{background:ItemSelected===1?'rgb(246 174 0)':ItemSelected===2?'rgb(11 67 0 / 86%)':'#ff3829'}}></div>)
  }

  const RequestItems=()=>{
  return (<>{ItemSelected===1? <div className='Hidtyr' style={{width:'100%',margin:'5%',maxWidth:Width}}>
    <div className='fi0Irf'>
      <div>
        <span style={{fontSize:'large'}}>No coming order found</span>
      </div>
    </div>
  </div>:ItemSelected===2? <div className='Hidtyr' style={{width:'100%',margin:'5%',maxWidth:Width}}>
                        <div className='fi0Irf'>
                          <div>
                            <span style={{fontSize:'large'}}>No delivered order found</span>
                          </div>
                        </div>
                      </div>: <div className='Hidtyr' style={{width:'100%',margin:'5%',maxWidth:Width}}>
                        <div className='fi0Irf'>
                          <div>
                            <span style={{fontSize:'large'}}>No canceled order found</span>
                          </div>
                        </div>
                      </div>}</>)
  }
  const HistHeading=()=>{
    return (<>{width<798? <> 
      <div className='hiStS_x topH_Dir' >
       <div className='histElim1 uXho_jk'>
         <div className='histElim1 csiHcls'>
           <div className='histElim1 f9IdcPd'>
             <div className='histElim1 uXho_j_'>
               <div className='histElim1 UDisp'>
                 <div className='histElim1'  style={{width:width}}>
                   <div className='eTchHea' onClick={()=>SetSelectedItem(1)} style={{width:width/3,left:'0',maxWidth:Width/3}}>
                     <div className='histElim1 HdieYd'style={{width:width/3}}>
                       <div className='HDuefDsg' style={{color:ItemSelected===1?'rgb(246 174 0)':'#c2c2c2'}}>Coming</div>
                       {ItemSelected===1&&<Picked/>}
                       </div>
                       </div>
                       <div className='eTchHea' onClick={()=>SetSelectedItem(2)}  style={{width:width/3,left:width/3,maxWidth:Width/3}}>
                         <div className='histElim1 HdieYd'style={{width:width/3}}>
                         <div className='HDuefDsg' style={{color:ItemSelected===2?'rgb(11 67 0 / 86%)':'#c2c2c2'}}>Delivered</div>
                         {ItemSelected===2&&<Picked/>}
                         </div>
                         </div>
                         <div className='eTchHea' onClick={()=>SetSelectedItem(3)} style={{width:width/3,left:width/3*2,maxWidth:Width/3}}>
                           <div className='histElim1 HdieYd'style={{width:width/3}}>
                           <div className='HDuefDsg' style={{color:ItemSelected===3?'#ff3829':'#c2c2c2'}}>Canceled</div>
                           {ItemSelected===3&&<Picked/>}
                           </div></div></div></div></div></div></div></div></div>
                           <RequestItems/>
                           </> :<div className='PdeEfrt'>
                            <div className='f5uedIDF'>
                              <div className='YrjkhUI'>
                                <div style={{   WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)'}}>
                                <div class="fhuSIrfKd" style={{
    fontWeight: '900',
    margin: '1px',
    fontSize: 'x-large',
    marginBottom: '17px'}}>Filter History</div>
                                  <div style={{unicodeBidi:'isolate'}}>
                                    <div className='eiYfheUenc' onClick={()=>SetSelectedItem(1)}><div style={{display:'flex'}}><input class="order_status_filter" type="radio" name="order_status" id="all_status" value="all_status"  style={{color:'red'}} checked={ItemSelected===1&&true}/><label for="all_status"></label><div className='fhuSIrfKd'>Coming</div></div>
                                    {ItemSelected===1&&<PickedPC/>}
                                    </div>
                                    <div className='eiYfheUenc' onClick={()=>SetSelectedItem(2)}><div style={{display:'flex'}}><input class="order_status_filter" type="radio" name="order_status" id="all_status" value="all_status"  checked={ItemSelected===2&&true}/><label for="all_status"></label><div className='fhuSIrfKd'>Delivered</div></div>
                                    {ItemSelected===2&&<PickedPC/>}</div>
                                    <div className='eiYfheUenc' onClick={()=>SetSelectedItem(3)}><div style={{display:'flex'}}><input class="order_status_filter" type="radio" name="order_status" id="all_status" value="all_status" checked={ItemSelected===3&&true}/><label for="all_status"></label><div className='fhuSIrfKd'>Cancled</div></div>
                                    {ItemSelected===3&&<PickedPC/>}</div>
                                  </div>
                                </div>
                              </div>
                              </div>  <div className='erioxfert'>
                                <div className='UdfUeUIedf'>
                                  <div className='HDkdoierf'> <RequestItems/></div>
                                </div>
                               </div></div>}</>)
  }
  return (<>

               <HistHeading/>
                     
                       </>
  )
}

export default History
