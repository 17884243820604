import React, { Component ,useState,useRef,useEffect, useMemo } from 'react'
import '../style-item/App.css'
import '../style-item/loadingCube.css'
import IconCall from '../icons/IconCall'
import { useMediaQuery } from 'react-responsive';
import Agent from '../deliver/sent'
import UserProfile from './userProfile'
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import ReactFacebookLogin from 'react-facebook-login';
import Countdown from 'react-countdown';
import CryptoJS from 'crypto-js';
import { useNavigate} from 'react-router-dom';


function SignIn (props){
const navigateTo=useNavigate()
  const secretPass1 = "sdjkljlsdlkJKjksdlkseOEjd";
  const secretPass2 = "kldsklfjddfdsdlkdfkkldfjkf";
  const secretPass4 = "fjdkdfkljgldfguitytlkseOEjd";
  const secretPass3 = "kfg6tythvcbdffgdfhgfhffjkf";
  const secretPass5 = "sdjkljlsdldfdrtdfdgfgghfEjd";
  const [OnRenderAuth,SetAuthRender]=useState(false);
  const [NewOtpReq,SetNewOtpReq]=useState(false)
  
  const login = useGoogleLogin({
    onSuccess: async (codeRes) => {try{
      document.cookie = `accessAuth=${codeRes.access_token}; expires=${codeRes.expires_in};path=/`;
    
     
     axios.get('https://www.googleapis.com/oauth2/v3/userinfo',{
        headers:{
          Authorization: `Bearer ${codeRes.access_token}`
        }
      }).then(function(g_data){  
        
     
       
   axios.get('/LoginUser',{headers:{
     c: g_data.data.email,
      a: codeRes.access_token,
      d: 'google',
      u: g_data.data.name,
      p: g_data.data.picture,}
    }).then(function(res){
      sessionStorage.setItem('uDeE',CryptoJS.AES.encrypt(g_data.data.email, secretPass1).toString())
      sessionStorage.setItem('uDnE',CryptoJS.AES.encrypt(g_data.data.name, secretPass2).toString())
      sessionStorage.setItem('uDpE',CryptoJS.AES.encrypt(g_data.data.picture, secretPass3).toString())
      sessionStorage.setItem('uDtE',CryptoJS.AES.encrypt(codeRes.access_token, secretPass4).toString())      
      navigateTo('./success')
      window.location.reload(true)
    })})
    }catch(e){
      console.log(e)
    }},
    onError:codeResponse => SetAuthRender(false),
    onNonOAuthError:codeResponse => SetAuthRender(false),
  });
  function loginx(){
    SetAuthRender(true)
    login()
   
}
  const facebookItem=useRef()
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });

 const [BackGINdtSo,RetunClassName]=useState('varifyBTN')
  const [MobileEnable,MobileEnableFun]=useState(true)
  const [OtpEnable,OtpEnableFun]=useState(false)
 
const OTPInput = ({ length = 6 }) => {
  const [BackGINdtSox,RetunClassNamex]=useState('varifyBTN')
    const [otp, setOtp] = useState(Array(length).fill(''));
    const inputs = useRef([]);
  
    const handleChange = (e, index) => {
      
      const { value } = e.target;
      if (value.match(/^\d$/)) {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
        if((otp[0]||index===0)&&(otp[1]||index===1)&&(otp[2]||index===2)&&(otp[3]||index===3)&&(otp[4]||index===4)&&(otp[5]||index===5)){
          RetunClassNamex('INdtSo')
        }else{
          RetunClassNamex('varifyBTN')
        }
        if (index < length - 1) {
          inputs.current[index + 1].focus();
        }
      }
  
      if (value === '' && index > 0) {
        inputs.current[index - 1].focus();
      }
     
    };
  
    const handleKeyDown = (e, index) => {
      if (e.key === 'Backspace') {
       
  
        if(e.target.id===5){
            const newOtp = [...otp];
            newOtp[5] = '';
            setOtp(newOtp);
          inputs.current[e.target.id-1].focus();
        
        }else{
            const newOtp = [...otp];
            newOtp[e.target.id-1] = '';
            setOtp(newOtp);
          inputs.current[e.target.id-1].focus();

        
        }
        
       
      }
      
      if((otp[0]||index===0)&&(otp[1]||index===1)&&(otp[2]||index===2)&&(otp[3]||index===3)&&(otp[4]||index===4)&&(otp[5]||index===5)){
        RetunClassNamex('INdtSo')
      }else{
        RetunClassNamex('varifyBTN')
      }
    };

  function ClickOtpSet(p,index){
    const newOtp = [...otp];
    newOtp[index] = '';
    setOtp(newOtp);
    inputs.current[index].focus();
  }
  function VerifyBtn (){
    return(


    <div className='_1Y9H1St'>
    <input type='button' id={BackGINdtSox} value={'verify'}/>
  </div>)
  }
    return (<>  <div className='cKliaU'>
      <div style={{ display: 'flex', justifyContent: 'center' }}>OTP has been sent To {MobileVerification.number}</div>
                            <div className='GIgCg'>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {otp.map((_, index) => (
          <input
          className='oIcrC'
            key={index}
            type="number"
            maxLength="1"
            id={index}
            value={otp[index]}
            onClick={(p)=>ClickOtpSet(p, index)}
            onChange={(e) => handleChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
        
            ref={(el) => (inputs.current[index] = el)}
           
           style={{ border: '1px solid #ccc'}}
          />
        ))}
      </div></div>
      <div style={{ display: 'flex' ,justifyContent: "end"}}><button disabled={NewOtpReq} style={{color:NewOtpReq?'green':'red'}}>{!NewOtpReq?<Countdown date={Date.now()+180000} onComplete={()=>SetNewOtpReq(true)}/>:'Resend Otp'}</button></div>
      </div>
      <VerifyBtn/>
  
      </>
    );
  };







    const [MobileVerification,MobileVerify]=useState({
        number:''});
    const [boxborder,boxborderC]=useState('cRrtDY gduDGj')
    const [boxborderB,boxborderCB]=useState('button')
    const OtpRequest =(n)=>{
        const {name,value}= n.target;
        MobileVerify({...MobileVerification,[name]:value});
       console.log(boxborderCB)
       if(value>6000000000&&value<10000000000){
        RetunClassName('INdtSo')
        }else{
          RetunClassName('varifyBTN')
        }
        if(value>0){
            boxborderC('cRrtDY gduDGj gduDGj_F')
       
            
        }else{
            boxborderC('cRrtDY gduDGj')
            
        }
    }

function otpSystem(){

if(MobileVerification.number>6000000000&&MobileVerification.number<10000000000){
 
  MobileEnableFun(false)
     OtpEnableFun(true)
     SetAuthRender(true)
}
}
function responseFacebook(response) {
  console.log(response)
}


function Methods(){
  function M1(){
    return ( <div className='djkrAs Mbkd23'>
      <div className='fdj12'  id='sK-OeFb-if'>
     
        <span className='djkrAs iconsMed'>
        <IconCall icon={'facebook'}/>
      
        </span>
        { OnRenderAuth? <div  className='skj-1d5' style={{opacity: '0.6'}}>{isMobile?'Facebook Login':'Login With Facebook'}</div>:
   <ReactFacebookLogin fields={'name,email,picture'} useRedirect={true} cssClass='skj-1d5'  isDisabled={OnRenderAuth} onClick={()=>SetAuthRender(true)} disableMobileRedirect={true} autoLoad={false} appId='1569365006990564' redirectUri={false} callback={(res)=>

    axios.get('/LoginUser',{headers:{
      c: res.email,
       a: res.accessToken,
       d: 'facebook',
       u: res.name,
       p:res.picture.data.url ,}
     }).then(function(resSer){
      sessionStorage.setItem('uDeE',CryptoJS.AES.encrypt(res.email, secretPass1).toString())
      sessionStorage.setItem('uDnE',CryptoJS.AES.encrypt(res.name, secretPass2).toString())
      sessionStorage.setItem('uDpE',CryptoJS.AES.encrypt(res.picture.data.url, secretPass3).toString())
      sessionStorage.setItem('uDtE',CryptoJS.AES.encrypt(res.accessToken, secretPass4).toString())  
      navigateTo('./success')
      window.location.reload(true)
   
    
         })
   } onFailure={()=>SetAuthRender(false)} textButton={isMobile?'Facebook Login':'Login With Facebook'} ></ReactFacebookLogin>}

        </div>
    </div>)
  }
  function M2(){
    return (<div className='djkrAs Mbkd23'>
      <div className='fdj12'   onClick={()=>OnRenderAuth?console.error()
      :loginx()} ><span className='djkrAs iconsMed'><IconCall icon={'google'}/></span> <span style={OnRenderAuth?{opacity: '0.6'}:{}} className='skj-1d5'>{isMobile?'Google Login':'Login With Google'}</span></div>
      </div>)
  }
  return(  <div className='djkrA mediaopt'>
   
    <M1/>
    <M2/>
  </div>)
}

function LoginValue(){
  return (<>{!OnRenderAuth&&<div className='diDHph' style={{zIndex:'120000'}} onClick={()=>{isDesktop && !OnRenderAuth&& props.SignedCheck(false)}} ></div>}
  {OnRenderAuth&&<div className='diDHph' style={{zIndex:'120001'}} onClick={()=>{isDesktop && !OnRenderAuth&& props.SignedCheck(false)}} >
  <div class="cube">
  <div class="sjiIe_ sjiIe_1"></div>
  <div class="sjiIe_ sjiIe_2"></div>
  <div class="sjiIe_ sjiIe_3"></div>
  <div class="sjiIe_ sjiIe_4"></div>
  <div class="sjiIe_ sjiIe_5"></div>
  <div class="sjiIe_ sjiIe_6"></div>
</div>
    </div>}
 
  <div className='kTzfEL'>
 <div className='cZWmxq'>
        <div className='hyKHMD'>
            <div className='ekcOkh'>
                <div className='keJdHh'>
                    <span className='dFnfJY'>Sign In</span>
                   </div>
                    
                <div className='keJdHh' onClick={()=>props.SignedCheck(false)}>
                    
                   <IconCall icon={'close'}/></div>
                    </div></div>
                    <div className='ksPdla'></div>
                    </div>


                    <div>
                      <h2 className='h2-Sgn'>Join With</h2>
                      <Methods/>
                    </div>
                    { MobileEnable&& <>   
                      <div>
                      <h2 class="h2-Sgn sUIee">Or</h2>
                        <h2 class="h2-Sgn  sUIee">Continue With Mobile Number</h2></div>
                    <div className='cKliaU'>
                        <div className='GIgCg'>
                          <div className={boxborder} style={{display:'flex'}}>
<input maxLength={10} font-weight="book" id='mobile' name='number' font-size="13px" type='number' placeholder="Enter Mobile Number" value={MobileVerification.number} autoFocus onChange={OtpRequest} className='fgeYxH'/>
                       
                            </div>
                            </div></div>
                           
                            <div className='_1Y9H1St'>
                            <input isDisabled={OnRenderAuth} type='button' onClick={otpSystem} id={BackGINdtSo} style={OnRenderAuth?{opacity: '0.6'}:{}}  value={'Request OTP'}/>
  </div></>}
  {OtpEnable&& <OTPInput  length={6}/>}
  </div>
  </>)
}
return (<><Agent.Consumer>{value=>(value?<UserProfile Signed={props.Signed} SignedCheck={props.SignedCheck}/>:<LoginValue/>)}</Agent.Consumer></>
  
      )
   
  
}

export default SignIn
