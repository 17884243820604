import React, { useState,useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './component/App';
import { BrowserRouter, Route, Router, Routes, useNavigate } from 'react-router-dom';
import Agent from './deliver/sent'
import Mysdk from './SDK/mysdk';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { ButtonDisable } from './SDK/mysdk';


const root = ReactDOM.createRoot(document.getElementById('root'));


function RoutFun() {
 

 
 /** 
   useEffect(() => {
    typeof window !== undefined &&
      window.document.addEventListener("contextmenu", (e) => {
        e.preventDefault();
      });
  }, []);

  document.onkeydown = function(e) {
    console.log(e.key)
    if(e.key === 'F12') {
       return false;
    }
    if(e.ctrlKey && e.shiftKey && e.key === 'I') {
       return false;
    }
    if(e.ctrlKey && e.shiftKey && e.key === 'C') {
       return false;
    }
    if(e.ctrlKey && e.shiftKey && e.key === 'J') {
       return false;
    }
    if(e.ctrlKey && e.key === 'u') {
       return false;
    }
  }
**/
    const [AuthToken,SetAuthToken]=useState(sessionStorage.getItem('uDtE')?sessionStorage.getItem('uDtE'):null)
    const [AuthUserName,SetAuthUserName]=useState(sessionStorage.getItem('uDnE')?true:null)
    const [AuthUserId,SetAuthUserId]=useState(sessionStorage.getItem('uDeE')?true:null)
  return (
 
<Agent.Provider prfUn={AuthUserName} prfULd={AuthUserId} value={AuthToken}>

<BrowserRouter>
<Routes>
<Route path="/success" element={ <App Nav={'./'}/>} />
<Route path="/" element={ <App AllPathAccess={true}/>} />
<Route path="/home" element={ <App home={true}/>} />
<Route path="/category" element={ <App category={true}/>} />
<Route path="/history" element={ <App history={true}/>} />
<Route path="/cart" element={ <App cart={true}/>} />
<Route path="/login" element={AuthToken? <App loginReq={false}/>:<App loginReq={true}/>} />
</Routes>
</BrowserRouter>
</Agent.Provider>
  )
}

export default RoutFun
