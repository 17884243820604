import React ,{ useState } from 'react'
import IconCall from '../../icons/IconCall'
import '../../style-item/mainitem.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';
import { useSwipeable } from 'react-swipeable';
import data from '../../testFiles/offer.json'
import { useMediaQuery } from 'react-responsive';
import Details from './Details';



function Home() {
    const handlers = useSwipeable({
        onSwipedLeft: () => console.log('Swiped left!'),
        onSwipedRight: () => console.log('Swiped right!'),
      });
      
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
  const Data=data.data
  const [DeatsilsData,SetDataDetails]=useState()
 const [offerDetails,ShowOfferDetailsSet]=useState(false)
function SwipOferPan(){
    return <>{isMobile? <Swiper
    modules={[Autoplay]}
    spaceBetween={50}
    slidesPerView={1}
    autoplay={{ delay: 3000 }}
    style={{maxWidth:'480px'}}
  >

  { Object.keys(Data).map((item, i) => ( <SwiperSlide>
  <div datatype='offer' onClick={()=>{
      SetDataDetails(Data[item])
      ShowOfferDetailsSet(true)}} className='lkjsdf'>
      <div className='ksjfok'>
          <div className='jksdIef'>
              <div className='ikdfjlgO'>
                {item}
              </div>
              <div className='dkfPODF'>
                  <div className='klsjoer'>{Data[item].title}</div>
                  <div className='sldIPO'><div className='Kdjfrr'>Read T&C</div></div>
              </div>
          </div>
      </div>
  </div>
 </SwiperSlide>))}
 </Swiper>: <div {...handlers}  datatype='offer' className='lkjsdf setupDextop'>
 { Object.keys(Data).map((item, i) => (  <div onClick={()=>{
      SetDataDetails(Data[item])
      ShowOfferDetailsSet(true)}} className='ksjfok flaxDisplay1 move'>
            <div className='jksdIef'>
                <div className='ikdfjlgO'>
                {item}
                </div>
                <div className='dkfPODF'>
                    <div className='klsjoer'>{Data[item].title}</div>
                    <div className='sldIPO'><div className='Kdjfrr'>Read T&C</div></div>
                </div>
            </div>
        </div>))}</div>}</>
}
  return (
   <div>
    <SwipOferPan/>{offerDetails&&<Details ShowOfferDetailsSet={ShowOfferDetailsSet} Data={DeatsilsData} reguard={'offer'}/>}
   
   </div>
  )
}

export default Home
